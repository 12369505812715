export const states = {
  off: 'off',
  on: 'on',
  question: 'question',
  win: 'win',
  lose: 'lose',
  level: 'level',
}

export const lettersPerLevel = {
  1: 100,
  2: 150,
  3: 200,
  4: 300,
  5: 400,
  6: 500,
  7: 600,
  8: 700,
  9: 750,
  10: 800,
}

import React from 'react'
import ReactDOM from 'react-dom'
import XRegExp from 'xregexp'
import App from './App'
import './assets/fonts/Calculator.ttf'
import './index.scss'
XRegExp.uninstall('namespacing')

ReactDOM.render(
  // <React.StrictMode >
  <App />,
  // </React.StrictMode>,
  document.getElementById('root')
)

// React is loaded and is available as React and ReactDOM
// imports should NOT be used

// class LinkLink extends React.Component {
//   constructor(props){
//     super(props)
//     this.state = {
//       props
//     }
//   }
//   render() {
//     return <ul>
//     {
//       this.state.props.items.map(item=>(
//         <li key={item.text}>
//           <Link item={item}/>
//         </li>
//       ))
//     }
//     </ul>;
//   }
// }

// function withList(ItemComponent) {
//   return (LinkLink);
// }

// class Link extends React.Component {
//   render() {
//     return <a href={ this.props.item.href }>{ this.props.item.text }</a>;
//   }
// }

// const LinkList = withList(Link);

// document.body.innerHTML = "<div id='root'></div>";
// const rootElement = document.getElementById("root");

// if(LinkList) {
//   let items = [{ href:"https://www.google.com", text:"Google" }, 
//     { href:"https://www.bing.com", text:"Bing" }];
//   ReactDOM.render(<LinkList items={items} />, rootElement);
//   console.log(rootElement.innerHTML);
// }
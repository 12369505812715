import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useMediaQuery } from 'react-responsive'
import moment from "moment"
import { CircularProgress } from "@mui/material"
import { resetData, setData, setProfile, setSound, setStatus } from "../../redux/slices/settings"
import { createGame } from "../../api/game"
import { getParagraph } from "../../api/paragraphs"
import { Keypad, Timer } from "../../components"
import Score from "../../components/score"
import { lettersPerLevel, states } from "../../constants/gameStates"
import { statuses } from "../../constants/statuses"
import { voicesOptions } from "../../constants/voices"
import { ThemeContext } from "../../contexts/theme-context"
import darkSwitch from "../../assets/Dark.svg"
import lightSwitch from "../../assets/Light.svg"
import correct from '../../assets/correct sound.wav'
import soundOff from '../../assets/sound_off.svg'
import soundOn from '../../assets/sound_on.svg'
import wrong from '../../assets/wrong sound.wav'
import winSound from '../../assets/Positive Game Win.wav'
import loseSound from '../../assets/Chime Lose.wav'
import styles from './styles.module.scss'

const correctAudio = new Audio(correct)
const wrongAudio = new Audio(wrong)
const winAudio = new Audio(winSound)
const loseAudio = new Audio(loseSound)
let msg = new SpeechSynthesisUtterance()

const GameScreen = () => {

  const { theme, setTheme } = useContext(ThemeContext)
  const [timeisUP, setTimeisUp] = useState(false)
  const [source, setSource] = useState('')
  const [paragraph, setParagraph] = useState([' ', ' '])
  const [check, setCheck] = useState(statuses.active)
  const [numberOfLetters, setNumberOfLetters] = useState('')
  const [loading, setLoading] = useState(false)
  const [playingSound, setPlayingSound] = useState(false)
  const [fetchedVoices, setFetchedVoices] = useState(false)
  const [voice, setVoice] = useState(null)

  const isMobile = useMediaQuery({ query: `(max-width: 760px)` })

  const classes = {
    'active': styles.active,
    'correct': styles.correct,
    'wrong': styles.wrong
  }
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const currentWordRef = useRef(null)

  const {
    sound,
    status,
    data: {
      word,
      index,
      level,
      tryNumber,
      letters,
      words,
      correctWords,
      timer,
      lastPlayed,
      shared
    }
  } = useSelector(state => state.settings)
  const time = new Date()
  time.setSeconds(time.getSeconds() + timer.seconds)
  time.setMinutes(time.getMinutes() + timer.minutes)

  //in-game functions

  const wordIndexAtLetter = (textArray, letterNumber) => {
    let count = 0, sum = 0
    //loop to get the sum of letter of previous levels 
    for (let k in lettersPerLevel) {
      if (k == level)
        break
      sum += lettersPerLevel[k]
    }
    //loop to find the index of the word where the paragraph should end at
    for (let i = index; i < textArray.length; i++) {
      if (textArray[i] !== '\\n') {
        count += textArray[i].replaceAll(/[^A-Za-zÀ-ÖØ-öø-ÿ0-9]/g, "").length
        if (count >= letterNumber) {
          return i
        }
      }
    }
    return -1
  }

  const nextWord = () => {
    if (!paragraph?.length)
      return
    setNumberOfLetters('')
    dispatch(setData({ word: paragraph[index + 1]?.replaceAll(/[^A-Za-zÀ-ÖØ-öø-ÿ0-9]/g, ""), index: index + 1 }))
  }

  useEffect(() => {
    setLoading(true)
    if (!fetchedVoices.length > 0) {
      setFetchedVoices(window.speechSynthesis.getVoices())
      setVoice(window.speechSynthesis.getVoices().filter(v => voicesOptions.some(s => v?.name?.includes(s)))[0])

    }
    else {
      setLoading(false)
    }
  }, [fetchedVoices])

  const checkInput = useCallback(() => {
    dispatch(setData({ words: words + 1 }))
    setNumberOfLetters('')
    if (numberOfLetters == word?.length) {
      if (voice) {
        msg.voice = voice
        msg.rate = voice?.name == "Microsoft Zira - English (United States)" ? 1.5 : 1
        msg.text = word
        // if (sound)
        //   window.speechSynthesis.speak(msg)
      }
      if (sound)
        correctAudio.play()
      setCheck(statuses.correct)
      setTimeout(() => {
        setCheck(statuses.active)
        dispatch(setData({ letters: letters + (parseInt(numberOfLetters) || 0), correctWords: correctWords + 1 }))
        nextWord()
      }, 100)
    } else {
      if (sound)
        wrongAudio.play()
      setCheck(statuses.wrong)
      setTimeout(() => {
        setCheck(statuses.active)
      }, 100)
    }
  }, [fetchedVoices, wrongAudio, words, index, letters, numberOfLetters, correctWords, status, msg, sound])



  const onGameOver = useCallback(async () => {
    setTimeisUp(true)
    try {
      dispatch(setData({ tryNumber: tryNumber + 1 }))
      setTimeout(async () => {
        setLoading(true)
        await createGame(moment().format('YYYY-MM-DD'), letters, words, correctWords, tryNumber, shared)
        if (loseAudio && sound)
          loseAudio.play()
        dispatch(setStatus(states.lose))
        setLoading(false)
      }, 500)
      // await createGame(moment().format('YYYY-MM-DD'), letters, words, correctWords, tryNumber, shared)
    } catch (error) {
      console.log(error)
    }
  }, [dispatch, letters, words, correctWords, tryNumber, shared])



  const keyDownHandler = useCallback(event => {
    if (event.key === 'Enter' && status !== states.win && status !== states.lose) {
      event.preventDefault()
      if (!playingSound)
        checkInput()
    }
  }, [numberOfLetters])

  useEffect(() => {
    document.addEventListener('keydown', keyDownHandler)
    return () => {
      document.removeEventListener('keydown', keyDownHandler)
    }
  }, [keyDownHandler])

  useEffect(() => {
    if (!lastPlayed || moment(lastPlayed).isBefore(moment().format('YYYY-MM-DD'), 'day')) {
      dispatch(setData({ lastPlayed: moment().format('YYYY-MM-DD'), tryNumber: 0 }))
      dispatch(resetData())
      dispatch(setStatus(states.on))
    }
  }, [])


  useEffect(() => {
    currentWordRef.current?.scrollIntoView({
      behavior: 'auto',
      block: 'start',
    })
    if (word == 'n') {
      nextWord()
    }
  }, [word, loading])


  useEffect(() => {
    if (paragraph[index] == '\\n')
      dispatch(setData({ word: paragraph[index + 1]?.replaceAll(/[^A-Za-zÀ-ÖØ-öø-ÿ0-9]/g, ""), index: index + 1 }))
    //Check if the index of the word is equal to the length of the paragraph which means that the player won
    //but also check if the letters are not equal to 0 which means its the start of a new level and the condition has to wait until paragraph is ready
    if (index == paragraph.length && letters !== 0) {
      localStorage.setItem("counter", "00:02:00")
      if (winAudio && sound)
        winAudio.play()
      dispatch(setStatus(states.win))
    }
  }, [index, paragraph, level])

  useEffect(() => {
    const getPara = async () => {
      try {
        setLoading(true)
        if (fetchedVoices?.length > 0 && wrongAudio) {
          const paraRes = await getParagraph(moment().format('YYYY-MM-DD'))
          const { text, source } = paraRes?.data?.paragraph
          let textArray = text?.replaceAll(/\n/g, ' \\n ').split(/[-\n\s\t]+/)
          setParagraph(textArray?.slice(0, wordIndexAtLetter(textArray, lettersPerLevel[level]) + 1))
          setSource(source)
          dispatch(setData({
            word: text
              ?.replaceAll(/\n/g, ' \\n ')
              .split(/[-\n\s\t]+/)[!lastPlayed || moment(lastPlayed).isBefore(moment().format('YYYY-MM-DD'), 'day')
                ? 0
                : index]?.replaceAll(/[^A-Za-zÀ-ÖØ-öø-ÿ0-9]/g, "")
          }))
        }
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }
    getPara().catch(console.error)

  }, [dispatch, fetchedVoices])


  //Handlers



  const handleChange = setter => e => {
    setter(e.target.value)
  }

  const handleSignout = () => {
    dispatch(setProfile({}))
  }


  return (
    <div className={`${styles.container} themed`}>
      <div className={styles.box}>
        {loading ? <div className={styles.spinnerContainer}>
          <CircularProgress color="yellow" />
        </div> : status == states.on &&
        <>
          <div className={styles.header}>
            <div className={styles.source}>
              @<a href={source}>{source}</a>
            </div>
            <div className={styles.settings}>
              <div className={styles.themeContainer} role="button" onClick={() => setTheme(theme == "light" ? "dark" : "light")}>
                <img src={theme == "dark" ? lightSwitch : darkSwitch} />
              </div>
              <div className={styles.muteContainer} role="button" onClick={() => { dispatch(setSound(!sound)) }}>
                <img src={sound ? soundOn : soundOff} alt={sound ? "on" : "off"} />
              </div>
            </div>
          </div>
          <div className={styles.level}>
            <div className={styles.levelText}>
              Level {level} / {lettersPerLevel[level]} letters
            </div>
            <div className={styles.levelProgressBarContainer}>
              <div className={styles.levelProgressBar}>

              </div>
              <div className={styles.levelProgressContainer} style={{
                width: `${((letters * 100) / lettersPerLevel[level]) + 10}%`
              }}>
                <div className={styles.percent}>
                  {letters}
                </div>
                <div className={styles.levelProgress}>
                </div>

              </div>
            </div>
          </div>
          <div className={styles.paragraph}>
            {paragraph?.slice(0, index)?.join(' ').replaceAll('\\n', '\n')}
            <span ref={currentWordRef} id={classes[check]} className={styles.word}>
              {index !== 0 ? ' ' : ''}{paragraph[index] == '\\n' ?
                '' : paragraph[index]}{index === paragraph?.length ? '' : ' '}
            </span>
            {paragraph?.slice(index + 1)?.join(' ').replaceAll('\\n', ' \n ')}
          </div>
          <div className={styles.bottom}>
            <div className={styles.elements}>
              <div className={styles.inputContainer}>
                <input
                  autoFocus={true}
                  className={styles.numberInput}
                  value={numberOfLetters}
                  readOnly={isMobile}
                  onChange={handleChange(setNumberOfLetters)}
                  type="number"
                />
              </div>
              <div className={styles.timerContainer}>
                {timeisUP ? 'Time is up!' : <Timer onExpire={onGameOver} />}
              </div>
            </div>
            {timeisUP ? '' : <Keypad next={!playingSound ? checkInput : () => { }} setter={setNumberOfLetters} />}
          </div>
        </>}
        {(status == states.win || status == states.lose) && <Score paragraph={paragraph} voice={voice} />}
      </div>
    </div >
  )
}

export default GameScreen


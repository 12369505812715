import { useContext, useMemo } from 'react'
import arrowLeft from '../../assets/arrow_left.svg'
import arrowLeftLight from '../../assets/arrow_left_light.svg'
import arrowRight from '../../assets/arrow_right.svg'
import arrowRightLight from '../../assets/arrow_right_light.svg'
import { ThemeContext } from '../../contexts/theme-context'
import NumberBox from "../numberBox"
import styles from "./styles.module.scss"
import { lettersPerLevel, states } from '../../constants/gameStates'
import { useSelector } from 'react-redux'
import zIndex from '@mui/material/styles/zIndex'




const LevelStepper = ({ setter, next }) => {

  const { theme } = useContext(ThemeContext)
  const { data: { level }, status } = useSelector(state => state?.settings)
  const lightTheme = useMemo(() => theme == 'light', [theme])
  const winState = useMemo(() => status == states.win, [status])

  return (
    <div className={styles.container}>
      {
        Object.keys(lettersPerLevel).map(key =>
          <div className={styles.stepContainer}>
            <div className={styles.line}></div>
            <div
              className={styles.box}
              style={{
                color: `${(winState ? level + 1 : level) == parseInt(key) ? '#303030' : theme == 'light' ?
                  (winState ? level + 1 : level) > parseInt(key) ? 'white' : '#303030'
                  : (winState ? level + 1 : level) > parseInt(key) ? '#303030' : 'white'} `,
                border: `${(winState ? level + 1 : level) == parseInt(key) ? 'none' : ` 1px solid ${theme == 'light' ? '#303030' : 'white'}`}`,
                zIndex: `${(winState ? level + 1 : level) >= parseInt(key) ? '4' : '2'}`,
                background: `${(winState ? level + 1 : level) == parseInt(key) ? '#faa51b' : theme == 'light' ?
                  (winState ? level + 1 : level) > parseInt(key) ? '#303030' : 'white'
                  : (winState ? level + 1 : level) > parseInt(key) ? 'white' : 'transparent'} `
              }}>
              {key}
            </div>
          </div>
        )
      }
    </div >
  )
}

export default LevelStepper
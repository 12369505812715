import { Share } from "@mui/icons-material"
import moment from "moment"
import { useContext, useEffect, useState } from "react"
import CopyToClipboard from 'react-copy-to-clipboard'
import { useDispatch, useSelector } from "react-redux"
import { useMediaQuery } from "react-responsive"
import useWebShare from 'react-use-web-share'
import WebFont from "webfontloader"
import home from '../../assets/home2.svg'
import { lettersPerLevel, states } from "../../constants/gameStates"
import { resetData, setData, setStatus } from "../../redux/slices/settings"
import { TomorrowTimer } from "../index"
import styles from "./styles.module.scss"
import { ThemeContext } from "../../contexts/theme-context"
import { useNavigate } from "react-router-dom"


const LevelScreen = ({ handleSignout }) => {


  const { data: { level } } = useSelector(state => state.settings)
  const { theme } = useContext(ThemeContext)
  const navigate = useNavigate()
  const dispatch = useDispatch()


  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Roboto']
      }
    })
  }, [])

  const handleClick = async () => {
    dispatch(setStatus(states.on))
    navigate('/play', { replace: true })
  }
  return (
    <div className={`${styles.container} themed`} >
      {/* <div className={styles.logoutContainer} onClick={handleSignout}>Logout</div> */}
      <div className={styles.levelContainer}>
        <div className={`${styles.level}`} style={{ fontWeight: "bold" }}>
          Level {level}
        </div>
        <p className={styles.description}>
          Count {lettersPerLevel[level]} letters
          <br />
          in 2 minutes
        </p>
      </div>
      <div className={styles.buttonContainer}>
        <button style={{ cursor: "pointer" }} onClick={handleClick}>Count</button>
      </div>

      <div className={styles.homeButton} onClick={() => { window.location.href = 'https://letterbored.io' }}>
        <img src={home} alt="home" />
      </div>
    </div >

  )
}

export default LevelScreen
import { ThemeProvider, createTheme } from "@mui/material"
import axios from "axios"
import { useSelector } from "react-redux"
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom"
import { GameScreen, LevelScreen, NotFound } from "../screens"
import { states } from "../constants/gameStates"

const theme = createTheme({
  palette: {
    yellow: {
      main: '#faa51b',
    },
  },
})

const AuthRouter = () => {
  const { profile, status } = useSelector(state => state.settings)
  axios.defaults.headers.common['authorization'] = profile?.authToken || ''
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route index path="/" element={!status || status == states.level ? <LevelScreen /> : <Navigate to={'/play'} replace />} />
          <Route path="/play" element={!status || status == states.level ? <Navigate to={'/'} replace /> : <GameScreen />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </Router >
    </ThemeProvider>
  )
}

export default AuthRouter

import { createSlice } from "@reduxjs/toolkit"
export const articlesSlice = createSlice({
  name: "articles",
  initialState: [],
  reducers: {
    set: (state, action) => action.payload,
    append: (state, action) => [...state,...action.payload],
   },
});

export const { set,append } = articlesSlice.actions;

export default articlesSlice.reducer;

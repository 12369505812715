import axios from "axios"
import { baseURL } from "./base"



export const getScore= async(date,letters,answer,numberOfTriesToday)=>{
  const payload = {date,letters,answer,numberOfTriesToday}
  const res= axios.post(`${baseURL}/games/score`,payload)
  return res
}
export const createGame= async(date,letters,words,correctWords,numberOfTriesToday,shared)=>{
  const payload = {date,letters,words,correctWords,numberOfTriesToday,shared}
  const res= axios.post(`${baseURL}/games/`,payload)
  return res
}
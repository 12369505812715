import { useState } from "react"
import styles from "./styles.module.scss"




const NumberBox = ({ color, textColor, content, active = false, onClick, ...otherProps }) => {

  const [clicked, setClicked] = useState(false)
  return (
    <div className={styles.container}
      onMouseDown={e => {
        e.preventDefault()
        onClick()
        setClicked(true)
      }} onMouseUp={e => { setClicked(false) }}
      style={{ backgroundColor: clicked ? "grey" : color, color: textColor, ...otherProps }}
    >
      {content}
    </div>
  )
}

export default NumberBox
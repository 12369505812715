import { useContext } from 'react'
import arrowLeft from '../../assets/arrow_left.svg'
import arrowLeftLight from '../../assets/arrow_left_light.svg'
import arrowRight from '../../assets/arrow_right.svg'
import arrowRightLight from '../../assets/arrow_right_light.svg'
import { ThemeContext } from '../../contexts/theme-context'
import NumberBox from "../numberBox"
import styles from "./styles.module.scss"

const customHandlerContents = {
  clearInput: 'Erase',
  nextWord: 'Next'
}

const Keypad = ({ setter, next }) => {

  const { theme } = useContext(ThemeContext)
  const rows = [
    ['1', '2', '3', customHandlerContents.clearInput],
    ['4', '5', '6', customHandlerContents.nextWord],
    ['7', '8', '9', '0'],
  ]

  const inputHandler = (v) => () => {
    setter(old => old + v)
  }

  const backspaceHandler = () => {
    setter('')
  }

  const customHandlers = {
    [customHandlerContents.clearInput]: backspaceHandler,
    [customHandlerContents.nextWord]: next,
  }
  const colorStyle = {
    customHandler: {
      light: "#303030",
      dark: "white"
    },
    number: {
      light: "white",
      dark: "#303030"
    }
  }

  return (
    <div className={styles.container}>
      {rows.map(row => (
        <div className={styles.row} key={row[0]}>
          {row.map(item => (
            <NumberBox
              key={item}
              content={item}
              color={customHandlers[item] ? colorStyle.customHandler[theme] : colorStyle.number[theme]}
              textColor={customHandlers[item] ? colorStyle.number[theme] : colorStyle.customHandler[theme]}
              onClick={customHandlers[item] || inputHandler(item)}
              {...customHandlers[item] && { ...{ fontSize: '1.5em' } }}
            />
          ))}
        </div>
      ))}
    </div>
  )
}

export default Keypad
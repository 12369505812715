import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import ticking from '../../assets/last 5 sec.wav'
import { states } from "../../constants/gameStates"
import styles from "./styles.module.scss"


const Timer = ({ onExpire }) => {

  const [startTime, setStartTime] = useState("00:02:00")
  const [counter, setCounter] = useState("00:03:00")
  const tickingAudio = new Audio(ticking)

  let value = startTime

  const getnewtime = (oTime) => {
    var pieces = oTime.split(":")
    var time = new Date()
    time.setHours(pieces[0])
    time.setMinutes(pieces[1])
    time.setSeconds(pieces[2])
    return time
  }
  const getnewtimestring = (oTime) => {
    if (oTime !== '00:00:00') {
      var timedif = new Date(getnewtime(oTime).valueOf() - 1000)
      var newtime = timedif.toTimeString().split(" ")[0]
      return newtime
    }
    return "00:00:00"
  }

  const getjudgetime = (jTime) => {
    return getnewtime(jTime) <= getnewtime("00:00:00") ? true : false
  }

  const { status, sound } = useSelector(state => state.settings)
  useEffect(() => {
    if (localStorage.getItem("counter")) {
      if (getjudgetime(localStorage.getItem("counter"))) {
        value = startTime
      } else {
        value = localStorage.getItem("counter")
      }
    } else {
      value = startTime
    }
    var counter = () => {

      if (getjudgetime(value)) {
        localStorage.setItem("counter", startTime)
        value = startTime
        setCounter(value)
      } else {
        if (value === '00:00:06' && sound)
          tickingAudio.play()
        if (status === states.win || status === states.lose)
          tickingAudio.pause()
        value = getnewtimestring(value)
        setCounter(value)
        localStorage.setItem("counter", getnewtimestring(value))
      }
      if (value === "00:00:00") {
        onExpire()
        value = startTime
        setCounter(startTime)
      }
    }
    if (status === states.on) {
      var interval = setInterval(counter, 1000)
      return () => clearInterval(interval)
    }

  }, [status, sound])








  return (
    <div className={`${styles.container} themed`}>
      <div className={styles.timer} >
        {counter !== "00:03:00" ? <span>{counter?.split(":")[1]}:{counter?.split(":")[2]}</span> : <span style={{ fontSize: '1rem' }}>Loading timer...</span>}
        {/* {counter !== "00:03:00" ? <span>{counter?.split(":")[1]}:{counter?.split(":")[2]}</span> : "02:00"} */}
      </div>
    </div >
  )


}

export default Timer
import React from "react"
import { useTimer } from 'react-timer-hook'
import styles from "./styles.module.scss"


const TomorrowTimer = ({ expiryTimestamp, onExpire }) => {
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({ expiryTimestamp, onExpire })




  return (
    <div className={styles.container}>
      <div className={styles.timer}>
        <span>{hours < 10 ? `0${hours}` : hours}</span>:<span>{minutes < 10 ? `0${minutes}` : minutes}</span>:<span>{seconds < 10 ? `0${seconds}` : seconds}</span>
      </div>
    </div >
  )


}

export default TomorrowTimer
import { createTheme } from '@mui/material'
import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import './App.scss'
import { ThemeProvider } from './contexts/theme-context'
import loadStore from './redux/store'
import AuthRouter from './router/auth'
const muitheme = createTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
})



const { store, persistor } = loadStore()



function App() {

  return (
    <ThemeProvider>
      <Provider store={store}>
        <PersistGate loading={<p>loading</p>} persistor={persistor}>
          <AuthRouter />
        </PersistGate>
      </Provider>
    </ThemeProvider>

  )
}

export default App

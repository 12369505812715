import { createContext, useEffect, useState } from "react"

export const ThemeContext = createContext({
  theme: '',
  setTheme: (theme) => { },
})
const isBrowserDefaultDark = () => window.matchMedia('(prefers-color-scheme: dark)').matches

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(isBrowserDefaultDark() ? "dark" : "light")

  useEffect(() => {
    const storedState = localStorage.getItem('theme')
    if (storedState) {
      setTheme(storedState)
    }
  }, [])

  useEffect(() => {
    localStorage.setItem('theme', theme)
  }, [theme])

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <div className={`theme-${theme}`}>
        {children}
      </div>
    </ThemeContext.Provider>
  )
}
import { Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import logo from '../../assets/logo_main.png'
import styles from "./styles.module.scss"
const NotFound = () => {
  const navigate = useNavigate()

  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <div className={styles.logoContainer}>
          <img src={logo} alt="lb" />
        </div>
        <div className={styles.authContainer}>
          <Typography align="center" variant="h1" color="#fff">404 Page Not Found</Typography>
          <div className={styles.button} onClick={() => { navigate('/') }}>Go to the game</div>
        </div>
      </div>
    </div >
  )
}

export default NotFound
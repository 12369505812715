/* eslint-disable import/no-anonymous-default-export */
import { configureStore } from '@reduxjs/toolkit'
import {
  FLUSH, PAUSE,
  PERSIST, persistReducer, persistStore, PURGE,
  REGISTER, REHYDRATE
} from 'redux-persist'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import storage from 'redux-persist/lib/storage'
import { articlesSlice, settingsSlice } from './slices'



const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: hardSet,
  version: 1,
  debug: true,
}

const persistedSettingsReducer = persistReducer(persistConfig, settingsSlice)

export default ()=>{
   const store = configureStore({
  reducer: {
    settings: persistedSettingsReducer,
    articles:articlesSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
})
const persistor = persistStore(store)
return { store, persistor }
}


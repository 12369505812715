import { createSlice } from "@reduxjs/toolkit"
import { states } from "../../constants/gameStates"


export const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    status: states.level,
    profile: {},
    sound: true,
    data: {
      lastPlayed: null,
      level: 1,
      question: '',
      word: '',
      index: 0,
      tryNumber: 2,
      letters: 0,
      words: 0,
      correctWords: 0,
      score: 0,
      correctAnswer: false,
      timer: { seconds: 0, minutes: 2 },
      shared: false
    }
  },
  reducers: {
    setProfile: (state, action) => {
      return { ...state, profile: action.payload }
    },
    setStatus: (state, action) => {
      return { ...state, status: action.payload }
    },
    setSound: (state, action) => {
      return { ...state, sound: action.payload }
    },
    setData: (state, action) => {
      return { ...state, data: { ...state.data, ...action.payload } }
    },
    nextLevelData: (state, action) => {
      return {
        ...state, data: {
          ...state.data,
          level: state.data.level + 1,
          correctWords: 0,
          score: 0,
          letters: 0,
          timer: { seconds: 0, minutes: 2 },
        }
      }
    },
    resetData: (state, action) => {
      return {
        ...state, data: {
          ...state.data,
          level: 1,
          word: '',
          index: 0,
          letters: 0,
          words: 0,
          correctWords: 0,
          score: 0,
          correctAnswer: false,
          timer: { seconds: 0, minutes: 2 },
          shared: false
        }
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const { setProfile, setStatus, setSound, setData, resetData, nextLevelData } = settingsSlice.actions

export default settingsSlice.reducer

import { Replay, Share, Speaker, VolumeUp } from "@mui/icons-material"
import moment from "moment"
import { useCallback, useContext, useEffect, useState } from "react"
import CopyToClipboard from 'react-copy-to-clipboard'
import { useDispatch, useSelector } from "react-redux"
import { useMediaQuery } from "react-responsive"
import WebFont from "webfontloader"
import home from '../../assets/home2.svg'
import { states } from "../../constants/gameStates"
import { nextLevelData, resetData, setData, setStatus } from "../../redux/slices/settings"
import { LevelStepper, TomorrowTimer } from "../index"
import styles from "./styles.module.scss"
import { ThemeContext } from "../../contexts/theme-context"
import { useNavigate } from "react-router-dom"
import arrowRight from '../../assets/arrow_right.svg'


const msg = new SpeechSynthesisUtterance()

const Score = ({ paragraph, voice }) => {

  const isMobile = useMediaQuery({ query: `(max-width: 760px)` })
  const { data: { tryNumber, letters, index, level }, status } = useSelector(state => state.settings)
  const { theme } = useContext(ThemeContext)
  const navigate = useNavigate()
  const [shareText, setShareText] = useState(`Level ${level} reached\nHow far can you count?`)
  const [copied, setCopied] = useState(false)
  const [text, setText] = useState(``)
  const [loading, setLoading] = useState(false)

  let now = moment()
  let timeDiff = moment(now).utcOffset(120).endOf('day') - now

  let dur = moment.duration(timeDiff)
  const time = new Date()
  time.setSeconds(time.getSeconds() + dur.seconds())
  time.setMinutes(time.getMinutes() + dur.minutes())
  time.setHours(time.getHours() + dur.hours())

  const dispatch = useDispatch()

  const counter = localStorage.getItem('counter')

  const onTimeExpire = () => {
    window.location.reload(false)
    dispatch(resetData())
  }

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Roboto']
      }
    })
  }, [])

  const handleNextLevel = async () => {
    dispatch(nextLevelData())
    navigate('/', { replace: true })
    window.speechSynthesis.cancel()
    dispatch(setStatus(states.level))
  }

  const handleClick = async () => {
    navigate('/', { replace: true })
    dispatch(setStatus(states.level))
    dispatch(resetData())
  }
  const handleListen = async () => {
    setLoading(true)
    if (voice && !window.speechSynthesis.speaking) {
      msg.voice = voice
      msg.rate = voice?.name === "Microsoft Zira - English (United States)" ? 1.5 : 0.8
      msg.text = paragraph.slice(0, index).join(' ')
      window.speechSynthesis.speak(msg)
      setLoading(false)

    }
  }

  const handleShare = useCallback(async () => {
    dispatch(setData({ shared: true }))
    // try {
    // const game = await createGame(moment().format('YYYY-MM-DD'), letters, words, correctWords, tryNumber, shared)
    // } catch (error) {
    //   console.log(error)
    // }
    // console.log(navigator)
    try {
      if (isMobile && navigator.share) {
        await navigator.share({ title: 'Letter bored', text: `Level ${level} reached\nHow far can you count?`, url: 'https://letterbored.io' })
      }
      else {
        setCopied(true)
        setTimeout(() => { setCopied(false) }, 4000)
      }
    } catch (error) {
      setText(error)
    }
  }, [level, counter, isMobile, text])

  return (
    <div className={styles.mainContainer}>
      <div className={styles.homeButton} onClick={() => { window.location.href = 'https://letterbored.io' }}>
        <img src={home} alt="home" />
      </div>
      <LevelStepper />
      <div className={styles.container} >
        <div className={styles.scoreContainer}>
          <div className={styles.scoreBox} style={{ border: `2px solid ${theme == 'light' ? 'black' : 'white'} `, color: `${theme == 'light' ? 'black' : 'white'}` }} >
            {
              tryNumber == 0 ? '2 more counts' : tryNumber == 1 ? '1 more count' : ''
            }
          </div>
          <div className={`${styles.score}`} style={{ fontWeight: "bold" }}>
            {status == states.win ? `Level ${level} Completed` : 'Try again!'}
          </div>
        </div>
        <div className={styles.buttonContainer}>
          {!isMobile ? <CopyToClipboard text={`${shareText}\nhttps://letterbored.io`}
            onCopy={handleShare}>
            <button>{copied ? `Copied` : `Share`}<Share fontSize="inherit" /></button>
          </CopyToClipboard>
            :
            <button onClick={handleShare}>{copied ? `Copied` : `Share`}<Share fontSize="inherit" /></button>}
        </div>
        <div className={styles.buttonContainer}>
          <button style={{ cursor: "pointer" }} onClick={handleListen}>Listen To Text  <VolumeUp />
          </button>
        </div>
        <div className={styles.buttonContainer}>
          {
            status == states.win && level != 10 ? <button style={{ cursor: "pointer" }} onClick={handleNextLevel}>Next Level <img src={arrowRight} /></button>
              : tryNumber <= 2 ?
                <button style={{ cursor: "pointer" }} onClick={handleClick}>Count Again <Replay /></button>
                : <button style={{ cursor: "default" }} onClick={null}>
                  <TomorrowTimer expiryTimestamp={time} onExpire={onTimeExpire} />
                </button>}
        </div>
      </div >

    </div>

  )
}

export default Score